import React from "react";
import { navigate } from "@reach/router";

//Components
import SimpleLanding from "../../../layouts/simple-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import Button from "../../../components/custom-widgets/button";
import Icon from "../../../components/custom-widgets/icon";

//Helpers
import useScrollOffset from "../../../helpers/useScrollOffset";

const CommercialAccountAgreement = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/customer-service",
      title: "Customer Service"
    },
    {
      id: 2,
      url: "/customer-service/account-details",
      title: "Account Details"
    },
    {
      id: 3,
      active: true,
      title: "Commercial Financing Account Agreement"
    }
  ];

  const SEOData = {
    title: "Commercial Financing Agreement",
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const TOCData = [
    {
      id: 1,
      text: "Definitions"
    },
    {
      id: 2,
      text: "Enrolling in the Online Banking Service"
    },
    {
      id: 3,
      text: "Security and User Responsibility for Online Banking Service"
    },
    {
      id: 4,
      text: "Access Requirements"
    },
    {
      id: 5,
      text: "Available Online Banking Services and Limitations"
    },
    {
      id: 6,
      text: "Wire Transfer Services (eWire)"
    },
    {
      id: 7,
      text: "Electronic Automated Clearing House Transactions (eACH)"
    },
    {
      id: 8,
      text: "Positive Pay Service"
    },
    {
      id: 9,
      text: "Fees"
    },
    {
      id: 10,
      text: "Electronic Invoicing through Popmoney<sup>&reg;</sup>"
    },
    {
      id: 11,
      text: "Placing Stop Payment Orders"
    },
    {
      id: 12,
      text: "Electronic Statements and Notices"
    },
    {
      id: 13,
      text: "Using E-mail to Send Information and Inquiries to Us"
    },
    {
      id: 14,
      text: "Third Party Services"
    },
    {
      id: 15,
      text: "Privacy"
    },
    {
      id: 16,
      text: "Liability Provisions"
    },
    {
      id: 17,
      text: "Indemnification"
    },
    {
      id: 18,
      text: "Notices and Communication"
    },
    {
      id: 19,
      text: "Availability of Services; Business Days"
    },
    {
      id: 20,
      text: "General Provisions",
      list: [
        {
          id: 1,
          text: "Amendments to this agreement"
        },
        {
          id: 2,
          text: "Termination of this agreement"
        },
        {
          id: 3,
          text: "Governing Law and Attorney Fees"
        },
        {
          id: 4,
          text: "Proprietary Rights"
        },
        {
          id: 5,
          text: "Entire Agreement"
        },
        {
          id: 6,
          text: "Bank Waivers"
        },
        {
          id: 7,
          text: "Jury Trial Waiver"
        }
      ]
    }
  ];
  const pagePath = "/customer-service/account-details/commercial-financing-account-agreement";
  const BackToTopLink = ({id}) => {
    return (
      <>
        <Button
          id={"nav-btn-back-to-top-" + id}
          type="link"
          url="#table-of-contents"
          text="Back to Top"
          class="btn-anchor-link d-flex no-min-width px-0 text-left"
          icon={{
            position: "left",
            lib: "fal",
            name: "arrow-up",
            class: "mt-1 mr-2"
          }}
        />
        <hr />
      </>
    );
  };

  //const half = Math.ceil(TOCData.length / 2);
  const TOCcol1 = TOCData.slice(0, 13);
  const TOCcol2 = TOCData.slice(13);
  const offset = useScrollOffset();

  return (
    <SimpleLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <p>
          <strong>WaFd Bank Online Banking Service Agreement for Commercial Account Customers</strong>
        </p>
        <p>
          <a
            className="text-decoration-none"
            title="WaFd Bank Online Banking Service Agreement"
            href="/documents/account-details/wafd-bank-online-banking-service-agreement.pdf"
            target="_blank"
          >
            <Icon name="file-pdf" class="mr-2" />
            Download PDF
          </a>
        </p>
        <p>
          Welcome to Online Banking at WaFd Bank. This Online Banking Service Agreement for Commercial Account Customers
          ("Agreement") sets out the terms and conditions for your use of WaFd Bank's Online Banking Service ("Online
          Banking Service") provided to our customers with commercial accounts.Please read this Agreement carefully. A
          printer friendly version of this Agreement is available on our Website. By using the Online Banking Service,
          you agree to the terms and conditions of this Agreement, including any instructions provided on our Website
          when using the Online Banking Service, and any changes in such terms and conditions (after their effective
          date). If you do not agree with these terms and conditions, you may not use the Online Banking Service.
        </p>
        <p>
          In this Agreement, the words "we", "us", "our", and "Bank" means WaFd Bank and the words "you" and "your" mean
          each Owner of an Eligible Account. Your use of the Online Banking Service is also governed by our Business
          Deposit Agreement and Disclosures our Schedule of Business Fees and Service Charges, our website Terms of Use,
          and any related Loan Account agreement (collectively, the "Account Agreements").
        </p>
        <p id="table-of-contents" style={{ scrollMarginTop: offset }}>
          <strong>Table of Contents</strong>
        </p>
        <div className="d-md-flex justify-content-between-md mb-4 p-md-3 border-md">
          <div className="col-md-6 border-md-right">
            <ol>
              {TOCcol1.map((item) => {
                const key = item.id;
                const id = "#toc-item-" + key;
                return (
                  <li key={key}>
                    <div
                      className="anchor-link font-weight-bold"
                      onClick={() => {
                        navigate(pagePath + id);
                      }}
                      role="button"
                    >
                      <span dangerouslySetInnerHTML={{ __html: item.text }} />
                    </div>
                    {item.list && (
                      <ol className="mt-2">
                        {item.list.map((subitem) => (
                          <li key={subitem.id}>
                            <strong>{subitem.text}</strong>
                          </li>
                        ))}
                      </ol>
                    )}
                  </li>
                );
              })}
            </ol>
          </div>
          <div className="col-md-6">
            <ol start={14}>
              {TOCcol2.map((item) => {
                const key = item.id;
                const id = "#toc-item-" + key;
                return (
                  <li key={key}>
                    <div
                      className="anchor-link font-weight-bold"
                      onClick={() => {
                        navigate(pagePath + id);
                      }}
                      role="button"
                    >
                      <span dangerouslySetInnerHTML={{ __html: item.text }} />
                    </div>
                    {item.list && (
                      <ol className="mt-2">
                        {item.list.map((subitem) => (
                          <li key={subitem.id}>
                            <strong>{subitem.text}</strong>
                          </li>
                        ))}
                      </ol>
                    )}
                  </li>
                );
              })}
            </ol>
          </div>
        </div>

        <div id="toc-item-1" style={{ scrollMarginTop: offset }}>
          <p className="lead">1. Definitions</p>
          <p>
            "Authorized Signer" means Authorized Signer and has the meaning given that term in the Business Account
            Agreement.
          </p>

          <p>
            "Available Balance" and "Available Funds" means funds on deposit in an Eligible Account and available for
            withdrawal, less monetary holds, pursuant to Regulation CC and the Bank's applicable funds availability
            schedule and policies.
          </p>
          <p>
            "Business Account Agreement" means the Business Deposit Agreement and Disclosures, as amended from time to
            time.
          </p>
          <p>"Business Day" means Monday through Friday, excluding federal banking holidays.</p>
          <p>
            "Business Online Banking Services Authorization Form" means the written document designating the Online
            Administrator and the schedules specifying limits and dollar thresholds with respect to certain services
            available through the Online Banking Service, and includes previously executed agreements, including any
            applicable Business Online Banking Agreement, Business Origination Wire Services Agreement, Positive Pay
            Services Agreement, and Electronic Automated Clearing House (eACH) Agreement.
          </p>
          <p>
            "Commercial" means, with respect to an account, an account established for purposes other than personal,
            family or household and designated by the Bank as commercial.
          </p>
          <p>
            "Deposit Account" means a checking, savings, money market or certificate of deposit account that you have
            established with us primarily for business purposes.
          </p>
          <p>"Eligible" means an account is accessible through the Online Banking Service.</p>
          <p>
            "Eligible Accounts" means Deposit Accounts and Loan Accounts that are accessible through the Online Banking
            Service.
          </p>
          <p>
            "Eligible Deposit Accounts" means a Deposit Account that is accessible through the Online Banking Service.
          </p>
          <p>"Eligible Loan Accounts" means Loan Accounts that are accessible through the Online Banking Service.</p>
          <p>"Fee Schedule" means the Schedule of Business Fees and Service Charges.</p>
          <p>"Loan Account" means your commercial loan accounts.</p>
          <p>
            "Online Administrator" means the individual designated in your Business Online Banking Services
            Authorization Form as the administrator of your Online Banking Services.
          </p>
          <p>"Online Banking Service" means the banking services offered by the Bank through our Website.</p>
          <p>
            "Online Financial Services" means each of the products and services you may access through the Online
            Banking Service, including services offered by third parties.
          </p>
          <p>"Owner" means the business organization designated in our records as an owner of an Eligible Account.</p>
          <p>
            "Transaction Processing Day" means a Business Day or any other day that the Bank processes transactions.
          </p>
          <p>
            "User Password" mean the password of the Online Administrator and any passwords created by the Online
            Administrator for secondary users of the Online Banking Service.
          </p>
          <p>
            "Website" means WaFd Bank's Online Banking Service website and other websites and webpages maintained by us
            and accessible through www.wafdbank.com.
          </p>
          <BackToTopLink id="01" />
        </div>

        <div id="toc-item-2" style={{ scrollMarginTop: offset }}>
          <p className="lead">2. Enrolling in Online Banking Services</p>
          <p>
            If you choose to enroll in the Online Banking Service, we will provide a unique user identification and
            online password to the Online Administrator. We reserve the right to change any aspect of the user
            identification and password system (i.e., the security system) associated with the Online Banking Service at
            any time without prior notice.
          </p>
          <BackToTopLink id="02" />
        </div>

        <div id="toc-item-3" style={{ scrollMarginTop: offset }}>
          <p className="lead">3. Security and User Responsibility for Online Banking Service</p>
          <ol>
            <li>
              <strong>Passwords</strong>
              <br />
              User Passwords can be changed within the Online Banking Service. User Passwords must meet the standards we
              establish from time to time. These standards will be available for your reference whenever you create or
              change a User Password. We may require you to change or update a User Password at any time. For security,
              the Online Banking Service will not allow access if anyone attempts to use a wrong password 5 times or
              more consecutively. We use additional log-in security that incorporates multiple factors to authenticate
              you as a customer.
            </li>
            <li>
              <strong>User Responsibility for Online Banking Service</strong>
              <br />
              You should never provide your User Passwords or related user identification information to a third party.
              If you do so, you are authorizing that party to conduct transactions on your Eligible Accounts. Therefore,
              you are responsible for any transactions, including transfers from or between your Eligible Accounts,
              resulting from you furnishing your User Passwords or related user identification information to a third
              party. The Bank will not be liable and will not reimburse you for any losses that may occur as a result of
              this authorized use of your User Password and related user identification information.
              <p>
                We will have no liability to you for any unauthorized payment or transfer made using your User Passwords
                that occurs before you have notified us of possible unauthorized use and we have had a reasonable
                opportunity to act on that notice. We may suspend or cancel your access even without receiving such
                notice from you if we suspect your User Passwords are being used in an unauthorized or fraudulent
                manner. You recognize that anyone possessing the User Passwords can access your Eligible Accounts and
                initiate transactions on those Accounts. You agree that you are responsible for maintaining the
                confidentiality and security of all User Passwords and related user identification information, and for
                implementing the necessary internal controls, balancing and reconciliation functions, and audit
                procedures to protect your Online Banking Service subscription and all your Eligible Accounts from theft
                or misuse.
              </p>
            </li>
            <li>
              <strong>Security Procedures</strong>
              <br />
              You agree that we may place session cookies and other identifiers on the computers and other devices you
              use to access the Online Banking Service. Session cookies help us confirm your identity and help us keep
              your transactions secure when you do online banking. We also use cookies for various purposes such as
              maintaining continuity during an online session, and optimizing the performance of our Website.
              <p>
                You agree that the use of the User Passwords and other security techniques that the Bank has established
                for the Online Banking Service constitutes a commercially reasonable security procedure for you. You
                agree to be bound by all payment requests, communications, or other instructions to the Bank that are
                initiated under your subscription to the Online Banking Service and in compliance with this security
                procedure (including any payment order and any cancellation or amendment of a payment order), regardless
                of whether or not you actually authorized the instruction. The use of User Passwords and other security
                measures have been established by the Bank to verify your authorization of the use of the Online Banking
                Service and are not designed for the detection of errors. If any instruction initiated under your
                subscription to Online Banking and accepted by the Bank in compliance with this security procedure
                contains any error, to the full extent allowed by law you shall be liable for, and shall indemnify the
                Bank against any claims, losses and expenses the Bank may incur that arise from or relate to the
                erroneous instructions. The Bank's records demonstrating compliance with this security procedure will be
                deemed conclusive proof that any payment request received by the Bank was authorized and that you are
                bound by those instructions.
              </p>
              <p>
                You acknowledge and agree that in the event you request the Bank to accept any request for Online
                Banking Services using any security procedures other than the security procedures set forth in this
                Agreement (which the Bank may accept or refuse at its discretion), then you will be deemed to have
                chosen other security procedures after the Bank offered and you refused security procedures that were
                commercially reasonable for you, and you expressly agree to be bound by any request, whether or not
                authorized, issued in your name in accordance with such other security procedures.
              </p>
            </li>
          </ol>
          <ol start={4}>
            <li>
              <strong>Notification to the Bank</strong>
              <br />
              You agree to immediately notify us of the following:
            </li>
          </ol>
          <ul>
            <ul>
              <li>
                You believe that any User Password or other user identification information may have been lost or
                stolen, or subject to unauthorized use; a device you use to access the Online Banking Service has been
                lost, stolen or compromised; or someone has viewed, downloaded, or deleted electronic records from your
                account or Online Banking Service without your permission.
              </li>
              <li>You suspect any fraudulent activity on your accounts.</li>
              <li>
                An individual with knowledge of the security procedures, User Passwords, codes, security devices, and/or
                related instructions is reassigned or leaves your employ, or is no longer authorized to act in regard to
                Online Banking Services.
              </li>
            </ul>
          </ul>
          <p>You will notify us by:</p>
          <blockquote>
            <ul>
              <li>
                calling <a href="tel:877-423-9742">877-423-9742</a>;
              </li>
              <li>
                writing to us at WaFd Bank, Attn: Commercial Account Service Center, 425 Pike Street, Seattle,
                Washington 98101; or
              </li>
              <li>visiting your local branch.</li>
            </ul>
          </blockquote>
          <p>
            Please refer to the Business Account Agreement for additional information regarding your obligation to
            notify us and your liability for unauthorized transactions on your account.
          </p>
          <BackToTopLink id="03" />
        </div>

        <div id="toc-item-4" style={{ scrollMarginTop: offset }}>
          <p className="lead">4. Access Requirements</p>
          <p>
            In order to use the Bank's Online Banking Service, you will need a personal computer (PC) or other
            Internet-enabled system ("Computer"), and any software and required equipment that can support the list of
            approved web browsers. The Website is best viewed using the most current version of Internet Explorer,
            Safari, Chrome, or Firefox. If you choose to use another browser to access the Website, we assume no
            responsibility for the resulting layout or content. You will also need a functioning email address. You are
            responsible for the installation, maintenance and operation of your Computer and all related costs. You are
            responsible for installing and maintaining appropriate virus protection on your Computer. We are not
            responsible for any errors or failures caused by any malfunction of your Computer, or your use of the Online
            Banking Service. We are not responsible for any viruses, spyware, malware, worms or related problems that
            may be associated with your Computer. We are also not responsible for any losses or delays in transmission
            of information that you provide to us or otherwise arising out of or incurred in connection with the use of
            any internet or other service provider providing your connection to the internet or any browser software.
            You are also responsible for all telephone, mobile remote access and other similar charges incurred in
            connecting to the Online Banking Service and for charges by any internet service providing connection to the
            internet.
          </p>
          <BackToTopLink id="04" />
        </div>

        <div id="toc-item-5" style={{ scrollMarginTop: offset }}>
          <p className="lead">5. Available Online Banking Services and Limitations</p>
          <ol>
            <li>
              <strong>Available Online Banking Services</strong>
              <br />
              Online Banking Services allow you to perform the following actions:
            </li>
          </ol>
          <ul>
            <ul>
              <li>Perform online administration of your Online Banking Services.</li>
              <li>
                Review a summary of your Eligible Deposit and Loan Accounts and account activity, including determining
                which checks have cleared and viewing images of cleared checks, wires and ACH transfers, for a period of
                13 months.
              </li>
              <li>Advance funds from Eligible Loan Accounts to Eligible Deposit Accounts.</li>
              <li>Transfer funds between Eligible Accounts (including one-time and recurring).</li>
              <li>Submit payments to Eligible Loan Accounts.</li>
              <li>Change passwords.</li>
              <li>Stop payment on checks that have not been paid.</li>
              <li>View 18 months of electronic statements if you have enrolled in E-statements.</li>
              <li>Send and receive secure electronic messaging to and from us.</li>
              <li>Initiate outgoing wire transfers (eWire).</li>
              <li>Initiate payment transactions through the Automated Clearing House Network (eACH).</li>
              <li>Upload information about issued checks and review checks presented for payment (Positive Pay).</li>
              <li>
                Initiate invoicing transactions and receive direct electronic payments (eInvoicing through Popmoney
                <sup>&reg;</sup>).
              </li>
            </ul>
          </ul>
          <p>
            We may periodically make available new internet services or products. You agree that if you use or acquire
            any such services or products after they become available, you will be legally bound by all additional
            terms, as well as by this Agreement.
          </p>
          <ol start={2}>
            <li>
              <strong>
                Conditions and Limitations of Online Banking Services
                <br />
                Savings and Money Market Accounts
              </strong>
              <p>
                Your ability to transfer funds between and/or make payments from certain Deposit Accounts is limited by
                law. For any Statement Savings Account(s) (including Money Market Accounts), you may make as many
                in-person withdrawals at a teller window or any ATM as you wish. However, federal regulations permit the
                depositor to make no more than a combined total of 6 pre-authorized, automatic, electronic (including
                computer initiated), telephone withdrawals or transfers, or by check, draft, debit card, or similar
                order payable to third parties in any monthly period. We may impose a fee, as disclosed in the
                <em>Fee Schedule</em>, for each such withdrawal in excess of 6 that you make in any monthly period.
                These fees will be reflected in your monthly statement. In addition, repeated violations will result in
                the Deposit Account being closed or changed from a savings type account to a transaction account.
              </p>
              <strong>Internal Transfers</strong>
              <br />
              <p>
                Funds can be transferred the same day or on a future date between your Eligible Accounts. Future-dated
                and recurring transfers are processed each calendar day between 9 PM and 11 PM Pacific Time. Same day
                transfers between Eligible Accounts are processed when received. If the transfer request is submitted
                prior to 11 PM Pacific Time it will be dated the same day as the request. Transfer requests submitted
                after 11 PM Pacific Time will be dated the next Transaction Processing Day.
              </p>
              <strong>Accessing More than One of Your Accounts</strong>
              <br />
              The Online Banking Service may not automatically allow you to view all of the accounts at our Bank of
              which you are the owner. To request additional Eligible Accounts, you must establish valid ownership and
              access of the account, and request through the Commercial Account Service Center at{" "}
              <a href="tel:877-423-9742">877-423-9742</a> or <a href="tel:206-626-8108">206-626-8108</a>, the addition
              of that account to your online profile.
            </li>
          </ol>
          <BackToTopLink id="05" />
        </div>

        <div id="toc-item-6" style={{ scrollMarginTop: offset }}>
          <p className="lead">6. Wire Transfer Services (eWire)</p>
          <p>
            You may initiate outgoing domestic wire transfers and international US Dollar wire transfers from certain
            types of Eligible Accounts (the "Wire Transfer Service"). Each Eligible Account from which we allow you to
            initiate wire transfers is referred to as a "Wire Transfer Account." From time to time, we may allow wire
            transfers to be initiated from additional types of Eligible Accounts, and any such additional types of
            Eligible Accounts will be automatically included in the Wire Transfer Accounts that you may access through
            the Wire Transfer Service.
          </p>
          <p>
            You may request the Wire Transfer Service by completing and submitting a Business Online Banking Services
            Authorization Form. By completing and submitting an Online Banking Services Authorization Form or permitting
            anyone to initiate a request for a wire transfer from a Wire Transfer Account ("Wire Transfer Request"), you
            agree to the terms and conditions of this Section.
          </p>
          <p>
            This Section incorporates: (i) the designations and other information you provide in the Business Online
            Banking Services Authorization Form; and (ii) any instructions or additional information that may be
            provided to you regarding the Wire Transfer Service by us or through the Online Banking Service.
          </p>
          <ol>
            <li>
              <strong>Authorization for Wire Transfer Service.</strong>
              <br />
              You authorize and direct us to process each Wire Transfer Request submitted through the Wire Transfer
              Service and in compliance with the security procedures described in this Agreement. We have no obligation
              at any time to process any Wire Transfer Request that exceeds the Available Balance in the Wire Transfer
              Account and any related linked account, at the time that you initiate the Wire Transfer Request. We have
              the right, but not the obligation, to refuse to process any Wire Transfer Request for the purpose of
              determining whether you authorized the Wire Transfer Request, and shall incur no liability for any delay
              caused by exercising this right. Wire transfers are "funds transfers" under and for purposes of Article 4A
              of the Uniform Commercial Code, as adopted in the state in which we maintain your Wire Transfer Accounts.
            </li>
            <li>
              <strong>Security Procedures</strong>.<br />
              In addition to using the User Passwords, you must use the security techniques that the Bank has
              established specifically for the Wire Transfer Service, which may include out of band authentication.
              These additional security techniques, together with the User Passwords and user identification
              information, are referred to as the "Wire Transfer Security Procedures." The Bank may change the Wire
              Transfer Security Procedures, and will provide instructions to you about the requirements of the Wire
              Transfer Security Procedures in effect from time to time. You agree that you will take all actions
              required to comply with the Wire Transfer Security Procedures. You agree to assume the risk of loss with
              respect to any wire transfer initiated through your subscription to the Online Banking Service using a
              User Password when the user has failed to comply with the Wire Transfer Security Procedures, and you agree
              to hold the Bank harmless from and against any loss caused by this failure.
            </li>
          </ol>
          <p>
            You agree that the Wire Transfer Security Procedures constitute a commercially reasonable security procedure
            for you. You agree to be bound by any Wire Transfer Request (or any request for cancellation or amendment of
            a Wire Transfer Request) initiated through the Wire Transfer Service and accepted by the Bank in compliance
            with the Wire Transfer Security Procedures, regardless of whether or not you actually authorized the Wire
            Transfer Request. If a Wire Transfer Request is accepted by the Bank in compliance with the Wire Transfer
            Security Procedures, and the Wire Transfer Request contains any error, to the full extent allowed by law you
            shall be liable for, and shall indemnify the Bank against, the amount of the Wire Transfer Request and all
            claims and all of the Bank's losses and expenses, including attorneys' fees, relating to the erroneous Wire
            Transfer Request. The Bank's records demonstrating compliance with the Wire Transfer Security Procedures
            with respect to any Wire Transfer Request will be deemed conclusive proof that the Wire Transfer Request
            received by the Bank was authorized and you are bound by that Wire Transfer Request.
          </p>
          <ol start={3}>
            <li>
              <strong>Liability for Authorized Requests.</strong>
              <br />
              If the Wire Transfer Request (or any request for cancellation or amendment of a Wire Transfer Request)
              received by the Bank was transmitted or authorized by you, you shall be obligated to pay the amount of the
              Wire Transfer Request regardless of (i) whether or not the Bank complied with the Wire Transfer Security
              Procedures with respect to that Wire Transfer Request, (ii) whether or not that Wire Transfer Request was
              erroneous in any respect, and (iii) whether or not that error would have been detected if the Bank had
              complied with the Wire Transfer Security Procedures.
            </li>
            <li>
              <strong>Wire Transfer Requests</strong>.<br />
              <p>
                <strong>Initiating Wire Transfers</strong>. Wire transfers shall be from Wire Transfer Accounts, and may
                be to any other account with the Bank, an affiliate of the Bank, or another bank or financial
                institution. You agree that, except as otherwise provided for in this Agreement, all Wire Transfer
                Requests originated through the Wire Transfer Service shall comply with the Wire Transfer Security
                Procedures, all other terms of this Agreement, and the terms and provisions of Article 4A of the Uniform
                Commercial Code, as adopted in the state in which the Bank maintains your accounts.
              </p>
              <p>
                <strong>Honoring Wire Transfers</strong>. We will accept a Wire Transfer Request only if the request is
                placed in accordance with the terms and requirements stated in this Agreement and the onscreen terms. We
                have no obligation to honor, either in whole or in part, the Wire Transfer Request (or the cancellation
                or amendment thereto) that: (i) exceeds the Available Balance in the Wire Transfer Account and any
                related linked account from which the transfer is requested; (ii) is not in accordance with each term
                and condition provided for in this Agreement and applicable law; (iii) is not, or we believe may not be,
                authorized or correct; or (iv) is impracticable or not feasible to accept. In addition, we shall be
                excused from failure or delay in issuing or accepting a Wire Transfer Request if such issuance or
                acceptance would result in the Bank's exceeding any limitation upon its intraday net funds position
                established pursuant to present or future guidelines or otherwise violating any provision of any present
                or future bank regulatory statute, rule, regulation or risk control program. If the applicable Wire
                Transfer Account does not contain sufficient funds for the requested wire transfer, we may, at our
                option, accept the Wire Transfer Request and debit any such account into overdraft, and we shall not be
                liable for damages to you as a result thereof; provided, we have no obligation to debit any such account
                into overdraft. If we create an overdraft to complete a wire transfer, you agree to pay to us the amount
                of the overdraft upon demand, and, if applicable, interest accruing on the amount of the overdraft from
                the date of its creation at the maximum rate of interest permitted under applicable law. You hereby
                authorize us to charge any of your accounts held by us at any time for such amount.
              </p>
              <p>
                Except as otherwise provided in this Section, we agree to use reasonable efforts to execute each Wire
                Transfer Request on the date received, provided that: (i) we receive your Wire Transfer Request no later
                than 2:00 PM, Pacific Time; and (ii) such day is a Business Day for us, the Federal Reserve, and the
                beneficiary's bank. In executing the Wire Transfer Request, we may utilize such means of transmission as
                we reasonably select. If you originate multiple Wire Transfer Requests for execution on the same day, we
                may execute the Wire Transfer Requests in any order convenient to us. If you direct us to use a specific
                intermediary bank or specific means of execution, you assume all risks of failure or of non-payment by
                the intermediary bank and all risks of loss by the means directed.
              </p>
              <strong>Canceling and Amending Wire Transfer Requests</strong>.If you wish to cancel or amend a Wire
              Transfer Request previously transmitted to us, you must transmit notice of the requested cancellation or
              amendment in compliance with this Agreement, and you must include the amount and recipient of the Wire
              Transfer Request in the notice. We will make a commercially reasonable effort to comply with any request
              you make to cancel or amend a Wire Transfer Request that is made before our acceptance of the Wire
              Transfer Request but only if our Wire Transfer Department has been given sufficient notice and a
              reasonable opportunity to act on it. We have no obligation or duty to cancel or amend a Wire Transfer
              Request received from you after we accept the Wire Transfer Request. If we consent to cancel or amend an
              accepted Wire Transfer Request, you shall indemnify and hold us harmless for all loss and expense,
              including attorneys' fees, relating to the cancellation and amendment or attempted cancellation and
              amendment.
            </li>
            <li>
              <strong>Types of Wire Transfers</strong>. The Wire Transfer Service permits you both to submit requests
              for one-time wire transfers and to develop templates for recurring and repetitive wire transfers. The
              Online Banking Service for the Wire Transfer Service provides instructions and procedures for submitting
              different kinds of wire transfers.
            </li>
            <li>
              <strong>Fees</strong>. You agree to pay and reimburse us for all governmental and third party fees or
              taxes arising out of or related to our providing the Wire Transfer Service to you.
            </li>
            <li>
              <strong>Reliance on Your Instructions</strong>. You understand and agree that, unless we, in our sole
              discretion, elect to confirm any Wire Transfer Request, we will not verify the terms of any Wire Transfer
              Request submitted under this Section. You are solely responsible for the accuracy and completeness of all
              data, instructions or other information provided to us regarding any wire transfer initiated. You
              authorize us to process each Wire Transfer Request submitted in accordance with the information that we
              receive from you in accordance with the security procedures in this Agreement. We shall have no
              responsibility for any erroneous information you provide and no liability for any losses you may suffer
              that arise from or relate to any erroneous information you provide for any Wire Transfer Request submitted
              hereunder.
            </li>
            <li>
              <strong>Representations and Warranties</strong>. You represent and warrant that each electronic
              communication submitted to us through the Wire Transfer Service, including without limitation, each Wire
              Transfer Request, shall comply with this Agreement and all applicable law. You also represent and warrant
              that: (a) no Wire Transfer Account is a "consumer account" as contemplated by the Electronic Fund Transfer
              Act, 15 U.S.C. 1693 et seq., and Regulation E, 12 CFR Part 205; and (b) you will submit a Wire Transfer
              Request on a Wire Transfer Account only for commercial purposes, and never for personal, family or
              household purposes. You agree to provide any additional information and to execute any additional
              documents or agreements that we may request in connection with your use of the Wire Transfer Service.
            </li>
            <li>
              <p>
                <strong>Identifying Information</strong>. You understand and agree that all wire transfers shall be
                executed on the basis of the following information provided in your Wire Transfer Request (which is
                referred to collectively as "Identifying Numbers"): (i) account number or other Identifying Number for
                the beneficiary; and (ii) the Identifying Number for any bank to or through which the funds are to be
                sent (a "Receiving Bank"). The Bank and any other bank processing the Wire Transfer Request (a
                "Processing Bank") may rely solely on the Identifying Numbers that you provide in your Wire Transfer
                Request, regardless of whether the order also identifies the beneficiary or Receiving Bank by name, and
                no Processing Bank has any obligation to confirm that any Identifying Number applies to any named
                beneficiary or Receiving Bank or to identify any discrepancy between any Identifying Number and the name
                provided for any beneficiary or Receiving Bank.
              </p>
              <p>
                We have no obligation to determine whether the recipient identified in any Wire Transfer Request is the
                accountholder for the account to which funds are to be transferred. If your instructions for any Wire
                Transfer Request contain any inconsistent information or other discrepancy regarding the name of the
                intended recipient and the account into which the funds are to be transferred, we shall make the
                transfer on the basis of the account number, even if that number identifies an account held by a person
                or entity other than the named recipient. If a Wire Transfer Request issued by you identifies an
                intermediary bank or beneficiary's bank only by an Identifying Number or by both name and Identifying
                Number, the Receiving Bank may rely on the Identifying Number as the proper identification of the
                intermediary bank or beneficiary's bank, even if such number identifies a person or entity different
                from the bank identified by name; and the Receiving Bank need not determine whether the name and number
                refer to the same person or entity or whether the number refers to the bank.
              </p>
              The foregoing is intended to be consistent with and subject to the terms and provisions of Sections 4A-207
              and 4A-208 of the Uniform Commercial Code as in effect in the state in which we maintain your accounts.
            </li>
          </ol>
          <BackToTopLink id="06" />
        </div>

        <div id="toc-item-7" style={{ scrollMarginTop: offset }}>
          <p className="lead">7. Electronic Automated Clearing House Transactions (eACH)</p>
          <ol>
            <li>
              <strong>Definitions</strong>
              <br />
              <p>
                "ACH" means the Automated Clearinghouse House electronic payments system or, as the context implies, an
                individual Automated Clearing House association.
              </p>
              <p>
                "Credit Entry" means an Entry originated by you to transfer funds to the account of a Designated Account
                Holder.
              </p>
              <p>
                "Debit Entry" means an Entry originated by you to transfer funds from the account of a Designated
                Account Holder.
              </p>
              <p>
                "Designated Account Holder" means persons or entities whose accounts you request to initiate funds
                transfers into or out of.
              </p>
              <p>
                "Effective Entry Date" means the pre-set date you choose for the entry to post to the Designated Account
                Holder's account. The actual posting will take place on the Settlement Date (see below).
              </p>
              <p>"Entry" means an electronic fund transfer transmitted via the ACH system under this Agreement.</p>
              <p>
                "Entries" shall have the meaning provided in the NACHA Rules and shall also mean (i) the data received
                from you hereunder from which the Bank prepares Entries, and (ii) for purposes of the Sections entitled
                "Cancellation and Amendment of Entries; Reversals," "Dual Control," and "Exceeding Exposure Limits," any
                request from you for reversal of an Entry.
              </p>
              <p>
                "Maximum Settlement Limit" means the maximum dollar value of all unsettled (i.e. outstanding) Debit and
                Credit Entries under all company identification numbers that Bank will allow.
              </p>
              <p>
                "ODFI" is an acronym for Originating Depository Financial Institution. For instance, the Bank operates
                as an ODFI when it initiates (originates) entries on your behalf.
              </p>
              <p>"On-Us Entry" means a debit or credit entry made between you and an account at the Bank.</p>
              <p>
                "Participating Depository Financial Institution" means a financial institution that (i) is authorized by
                applicable legal requirements to accept deposits, (ii) has been assigned a routing number by Accuity,
                and (iii) has agreed to be bound by the NACHA Rules.
              </p>
              <p>
                "RDFI" is an acronym for Receiving Depository Financial Institution. For instance, the Bank operates as
                an RDFI when it receives an entry from ACH and debits or credits your accounts as instructed by the
                entry.
              </p>
              <p>"NACHA Rules" has the meaning given in the paragraph below entitled "NACHA Rules".</p>
              <p>
                "Security Procedures" means, collectively, all of those procedures contained within this Agreement and
                the NACHA Rules that are designed to stop the dissemination and transmission of unauthorized Entries.
              </p>
              "Settlement Date" means the date that an exchange of funds with respect to an Entry is reflected on the
              books of the Federal Reserve Bank(s). Funds will post to the Designated Account Holder's account on
              Settlement Date.
            </li>
            <li>
              <strong>NACHA Rules</strong>
              <br />
              By using the ACH service through the Online Banking Service, you agree to comply with the Operating Rules
              of the NACHA in existence as of the date of this Agreement and as amended or created from time to time,
              but at least annually(herein collectively referred to as the "NACHA Rules"). We reserve the right to audit
              your compliance with the NACHA Rules and this Agreement. At your request, we will make a copy of the NACHA
              Rules available for you.
            </li>
            <li>
              <strong>Compliance with Law</strong>.
              <br />
              You agree that you will not initiate any ACH entries in violation of federal, state, or local law. You are
              responsible for ensuring that any Entries initiated through the ACH are not subject to sanctions enforced
              by the Office of Foreign Assets Control (OFAC). You agree that if we identify a potential violation of
              OFAC sanctions that the Bank may act upon the Entry as required by law and in accordance with the NACHA
              Rules. You are restricted to only transmitting PPD (Prearranged Payment and Deposit) and CCD (Corporate
              Credit or Debit) Entries.
            </li>
            <li>
              <strong>
                Delivery of Entries to the Bank
                <br />
                Delivery Requirements.
              </strong>
              <p>
                All Entries shall be delivered by you to the Bank in compliance with the formatting and other
                requirements set forth by the Bank and this Agreement.
              </p>
              <p>
                <strong>Maximum Settlement Limit</strong>. The Bank may set a Maximum Settlement Limit for you in
                writing and may from time to time increase or decrease this limit. The Bank will also set batch limits
                commensurate with your ACH processing needs. The total dollar amount of Entries transmitted by you to
                the Bank shall not exceed this Maximum Settlement Limit. The Bank will reject batches that exceed the
                limits set forth and will notify you if such rejection occurs. To avoid processing delays or the Bank
                rejection of batches, you agree not to send batches that exceed the limits set by the Bank.
              </p>
              <p>
                <strong>Security Procedures</strong>. You and the Bank will comply with the security procedures with
                respect to Entries transmitted by you to the Bank. The Bank may reject any Entry that it believes or
                suspects does not comply with the security procedures.
              </p>
              <p>
                <strong>Designated Account Holder Authorizations and Record Retention</strong>. Before the initiation by
                you of the first Entry to a Designated Account Holder's Account, you shall obtain from such Designated
                Account Holder an authorization to initiate one or more Entries to the Designated Account Holder's
                Account, which authorization shall comply with the NACHA Rules. You shall provide Designated Account
                Holder with a copy of such authorization. Each Entry thereafter shall be made pursuant to such
                authorization, and you will not initiate an Entry after such authorization has been revoked or the
                arrangement between you and such Designated Account Holder has terminated. You will retain Designated
                Account Holder authorizations for two years after they are terminated and other documents related to
                Entries for a period of two years. You shall immediately furnish such authorizations and documents to
                the Bank upon the Bank's request.
              </p>
              <p>
                <strong>Cancellation and Amendment of Entries; Reversals</strong>. Through the Online Banking Service
                you may cancel an Entry if it has not yet been delivered to the Bank or submit a reversal of an Entry up
                to 5 calendar days after the Settlement Date. The Bank may at its discretion use reasonable efforts to
                act on a request by you for reversal of an Entry file pursuant to the NACHA Rules; provided, however,
                that Bank shall not be liable for interest or losses if such reversal is not effected. Any request by
                you for reversal of an Entry must comply with the provisions of the sections entitled Delivery
                Requirements and Security Procedures. You shall reimburse the Bank for any expenses, losses, or damages
                the Bank may incur in effecting or attempting to complete your request for the reversal of an Entry. The
                Bank shall be entitled to payment from you in the amount of any such reversal of a Debit Entry file
                prior to acting on any re
              </p>
              <p>
                <strong>Designated Account Holder's Right to Refund Debit Entry</strong>. You acknowledge that a
                Designated Account Holder, under the NACHA Rules, has the right to obtain a refund of funds debited from
                a Designated Account Holder's Account by such Designated Account Holder's sending of a notice to the
                RDFI that a Debit Entry was, in whole or in part, not authorized, and the right of the RDFI to obtain
                payment of the amount of such Entry from an ODFI by transmitting an adjustment entry (as described in
                the NACHA Rules, and hereinafter "Adjustment Entry") in the amount of such refund. You agree to pay the
                Bank the amount of (i) any such adjustment entry upon its receipt by the Bank, and (ii) with respect to
                any On-Us Entry, any refund paid by the Bank to a Designated Account Holder in connection with such
                Designated Account Holder's claim that an Entry was, in whole or in part, not authorized.
              </p>
              <strong>Notice to Designated Account Holders</strong>. If the amount of a Debit Entry to a Designated
              Account Holder's Account varies in amount from the previous Debit Entry relating to the same authorization
              or preauthorized amount, you shall, at least 10 calendar days before the Effective Entry Date of such
              Debit Entry, send the Designated Account Holder written notice of the amount of such Debit Entry and its
              Effective Entry Date, unless Designated Account Holder has previously been notified of Designated Account
              Holder's right to receive such notice and Designated Account Holder has elected to receive such notice
              only when the Debit Entry does not fall within a specified range of amounts or varies from the most recent
              Debit Entry by an agreed amount. If any change is made by you in the scheduled Effective Entry Date of one
              or more Debit Entries, you shall, at least 7 calendar days before the Effective Entry Date of the first
              such Debit Entry to be affected by such change, send the Designated Account Holder a written notice of the
              new Effective Entry Date(s) of such Entry or Entries.
            </li>
            <li>
              <strong>
                Processing, Transmittal and Settlement by Bank
                <br />
                Processing, Transmittal and Settlement.
              </strong>
              <p>
                Except as otherwise provided herein, the Bank shall (i) process Entries received from you to conform
                with the requirements set forth in this Agreement, (ii) transmit such Entries as an ODFI to an Automated
                Clearing House processor ("ACH") selected by the Bank in its sole discretion, and (iii) settle for such
                Entries as provided in the NACHA Rules.
              </p>
              <p>
                <strong>Timing</strong>. The Bank shall transmit such Entries to the ACH by the deadline of the ACH
                either one or two Business Days prior to the Effective Entry Date shown in such Entries, provided that
                (i) such Entries are received by the Bank's related cut-off time. Files must be received by the Bank no
                later than 5 PM Pacific Time one Business Day prior to the Effective Entry date, on a Business Day, (ii)
                the Effective Entry Date is at least one Business Day after such Business Day, and (iii) the ACH is open
                for business on such Business Day. Entries shall be deemed received by the Bank when the electronic
                transmission (and compliance with any related Security Procedure) is completed as provided in the
                Delivery Requirements.
              </p>
              <p>
                If any of the requirements of clauses (i) or (ii) of the preceding paragraph are not met, the Bank shall
                use reasonable efforts to transmit such Entries to the ACH by the next deposit deadline of the ACH that
                is a Business Day.
              </p>
              The Bank shall transmit Entries with an Effective Date that settles on a Federal Holiday the Business Day
              prior to the Holiday. The Bank is not required to transmit any Entries earlier than a Federal Holiday to
              accommodate an Effective Entry date that would result in an Entry being submitted to the ACH in excess of
              three Business Days prior to the Effective Entry date. You are responsible for the timing of Entries to
              correspond to the Settlement Date of such Entries.
            </li>
            <li>
              <strong>On-Us Entries</strong>
              <br />
              Except as provided in the Section entitled "Rejection of Entries," in the case of an On-Us Entry, we will
              credit/debit the Designated Account Holder's account in the amount of such Entry on the Effective Entry
              Date contained in such Entry, provided the requirements set forth in clauses (i) and (ii) of the Section
              entitled "Timing" are met. If either of those requirements is not met, the Bank shall use reasonable
              efforts to credit/debit the Designated Account Holder's account in the amount of such Entry on the next
              Business Day following such Effective Entry Date.
            </li>
            <li>
              <strong>Rejection of Entries</strong>
              <br />
              The Bank may reject any Entry for any reason. The Bank shall in good faith attempt to notify you of such
              rejection (either electronically, in writing, by telephone, or as otherwise agreed to by the Bank and you)
              no later than the Effective Entry Date of the rejected Entry. The Bank shall not be liable to you for the
              rejection of any Entry, for your non-receipt of a notice given to you, or for the failure to give notice
              of rejection at an earlier time than that provided for herein. The Bank shall not be required to pay you
              interest on a rejected Entry for the period from rejection of the Entry to your receipt of the notice of
              rejection.
            </li>
            <li>
              <strong>Notice of Returned Entries</strong>
              <br />
              The Bank shall notify you either electronically, in writing, by telephone, or as otherwise agreed to by
              the Bank and you of the receipt of an Entry returned by the ACH no later than one Business Day after the
              Bank's receipt of the returned Entry. The Bank shall have no obligation to re-execute a returned Entry.
            </li>
            <li>
              <strong>Provisional Payment</strong>
              <br />
              You represent to the Bank and agree to be bound by the provision of the NACHA Rules making payment of an
              Entry by the RDFI to the Designated Account Holder provisional until receipt by the RDFI of final
              settlement for such Entry, and specifically acknowledge receipt of notice of that NACHA Rule and of the
              fact that, if such settlement is not received, the RDFI shall be entitled to a refund from the Designated
              Account Holder of the amount credited and you shall not be deemed to have paid the Designated Account
              Holder the amount of the Entry.
            </li>
            <li>
              <strong>
                Security Procedures; Unauthorized and Authorized Entries; Inconsistent Entries
                <br />
                Purpose of Security Procedures.
              </strong>
              <p>
                You agree that the purpose of the security procedures set out in this Section J is to verify the
                authenticity of Entries transmitted to the Bank in your name and not to detect an error in the
                transmission or content of any Entry, and that no security procedure for the detection of such errors
                has been agreed upon by you and us.
              </p>
              <p>
                <strong>Single Approval and Dual Approval</strong>. The Bank may require Dual Control on all Entries
                initiated through ACH. In effect, you will be required to have two different individuals initiate and
                approve any file submission to the Bank. You may establish a dollar limit threshold that will allow
                submission of files by a single individual if the threshold is not exceeded.
              </p>
              <p>
                <strong>Exceeding Exposure Limits</strong>. The Bank will reject any file that exceeds the exposure
                limits designated by the Bank ("Exposure Limits"). Requests to exceed the Exposure Limits must be
                approved prior to any transmission of files that would exceed the Exposure Limit.
              </p>
              <p>
                <strong>Authorized Entries</strong>. If an Entry received by the Bank was transmitted or authorized by
                you, it shall be effective as your Entry as provided herein, whether or not that Entry was erroneous in
                any respect.
              </p>
              <p>
                <strong>Unauthorized Entries</strong>. If an Entry delivered to the Bank was not in fact authorized by
                you, it shall be considered authorized if the Bank has accepted the Entry in compliance with the
                security procedures with respect to such Entry. In the event you are aware of the possibility of error
                or lack of authority in the transmission of an Entry or any request or communication relating thereto,
                regardless of source of information regarding such error or lack of authority, you shall immediately
                notify the Bank by telephone, followed by confirmation in writing. YOU ARE LIABLE FOR ANY UNAUTHORIZED
                ENTRIES THAT COMPLY WITH THE SECURITY PROCEDURES.
              </p>
              <strong>Inconsistent Entries</strong>. You acknowledge and agree that (i) if an Entry describes the
              Designated Account Holder inconsistently by name and account number, payment of the Entry transmitted by
              the Bank to an RDFI might be made by the RDFI (or by the Bank in the case of an On-Us Entry) on the basis
              of the account number even if it identifies a person different from the named Designated Account Holder,
              and that your obligations to pay the amount of the Entry to the Bank is not excused in such circumstances,
              and (ii) if an Entry describes a RDFI inconsistently by name and identifying number, payment of the Entry
              might be made on the basis of the identifying number even if it identifies an RDFI different from the
              named RDFI, and that your obligation to pay the amount of the entry to the Bank is not excused in such
              circumstances.
            </li>
            <li>
              <strong>Maintenance of Available Funds</strong>
              <br />
              You shall at all times maintain sufficient Available Funds in your Eligible Deposit Accounts to pay the
              amount of all Credit Entries, returned or reversed Debit Entries, Adjustment Entries, fees and other
              amounts which you are obligated to pay the Bank under this Agreement.
            </li>
            <li>
              <strong>Credit Entries</strong>
              <br />
              The Bank in its sole discretion may require you to have sufficient Available Funds in your Eligible
              Deposit Accounts to cover the amount of a Credit Entry prior to the Bank's transmittal of such Entry to an
              ACH or the Bank's crediting of a Designated Account Holder's Account in the amount of an On-Us Entry. In
              the absence of such a requirement, you will be obligated to have such available funds in your Eligible
              Deposit Accounts at such time on the Settlement Date with respect to such Entry or, in the case of an
              On-Us Entry, the date of crediting the Designated Account Holder's Account, as the Bank, in its
              discretion, may determine.
            </li>
            <li>
              <strong>Debit Entries</strong>
              <br />
              The Bank in its sole discretion may defer any credit to your Eligible Deposit Account with respect to a
              Debit Entry until it has received final settlement for such Entry. Any credit provided prior to that time
              shall be provisional and the Bank shall have the right to immediate payment by you upon the Bank's receipt
              of notice that final settlement has not occurred. In addition, the Bank shall have the right to payment by
              you of the amount of any returned or rejected Entry for which you have previously received credit upon its
              receipt by the Bank.
            </li>
            <li>
              <strong>Your Representations and Warranties</strong>
              <br />
              You represent and warrant that for each Entry you deliver to the Bank:
              <br />
              (i.) The Entry complies with the terms of this Agreement and the NACHA Rules
              <br />
              (ii.) You have complied with the NACHA Rules with respect to the Entry
              <br />
              (iii.) There has not been and shall not later be any breach of any warranty by you as an originator or of
              the Bank as an ODFI under the NACHA Rules
              <br />
              (iv.) You have not breached any warranty contained in this Agreement
              <br />
              (v.) The Entry complies with all applicable local, state, and federal regulations, including but not
              limited to the Electronic Fund Transfer Act and Regulation E.
            </li>
            <li>
              <strong>Notices and Statements</strong>
              <br />
              The Bank is not required to and may not provide you or Designated Account Holders with notice of receipt,
              the Bank's transmittal, or crediting/debiting of Entries. Entries and other debits and credits to your
              Eligible Deposit Account will be reflected on the periodic statement for your Eligible Deposit Account.
              You will notify the Bank of any unauthorized or erroneous Entries or any other discrepancy or error on the
              periodic statement as provided in the Business Account Agreement. The Bank will not be liable for any
              interest or losses resulting from your failure to give such notice.
            </li>
            <li>
              <strong>Confidentiality</strong>
              <br />
              You acknowledge that you will have access to certain confidential information regarding the Bank's
              execution of Entries, the Security Procedures, and the services contemplated by this Agreement. You will
              not disclose any confidential information of the Bank and shall use such confidential information only in
              connection with the transactions contemplated by this Agreement.
            </li>
            <li>
              <strong>Delivery Media and Records</strong>
              <br />
              All delivery media, Entries, Security Procedures, and records used by the Bank for transactions
              contemplated by this Agreement shall be and remain the Bank's property. The Bank may, in its sole
              discretion, make available such information upon your request. Any expenses incurred by the Bank in making
              any such information available to you shall be paid by you.
            </li>
            <li>
              <strong>Instructions and Notices</strong>
              <br />
              We will be entitled to rely on any oral or written notice, response, or other communication we believe to
              be genuine and to have been provided by the Online Administrator, and any such communication shall be
              deemed to have been provided by such person on your behalf. Any Entry or other data or information
              received by the Bank from or transmitted by the Bank to a third party data processor selected by you shall
              be deemed to have been received from or transmitted to you, and such vendor shall be deemed your agent.
              You must provide us with notice of use of such vendors and notice of change of vendors. Such notice shall
              be effective on a date agreed to by both parties.
            </li>
          </ol>
          <BackToTopLink id="07" />
        </div>

        <div id="toc-item-8" style={{ scrollMarginTop: offset }}>
          <p className="lead">8. Positive Pay Service</p>
          <ol>
            <li>
              <strong>Definitions</strong>
              <p>
                "Authorized Account" means your accounts maintained at the Bank, to which the Positive Pay Services
                rendered by the Bank will apply.
              </p>
              <p>
                "Check Issue Report" means a record describing checks drawn by you on an Authorized Account you provide
                to us under the Section entitled "Check Issue Report."
              </p>
              <p>
                "Exception Check" means a Presented Check that does not match a check included in a Check Issue Report.
              </p>
              <p>
                "Exception Check List" means a record describing Exception Checks we make available to you through the
                Online Banking Service.
              </p>
              <p>"Pay/Return Request" means your instructions to us ordering us to pay or return an Exception Check.</p>
              "Presented Check" means a check drawn on an Authorized Account and presented to the Bank for payment
              through the check collection system.
            </li>
            <li>
              <strong>
                Positive Pay Services
                <br />
                Check Issue Report.
              </strong>
              <p>
                You will submit a Check Issue Report to the Bank each day you issue checks on an Authorized Account
                through the Online Banking Service. The Check Issue Report shall accurately state the check number and
                the exact dollar amount of each check drawn on each Authorized Account since the last Check Issue Report
                was submitted.
              </p>
              <p>
                <strong>Payment of Presented Checks and Reporting of Exception Checks</strong>. The Bank will compare
                each Presented Check by check number and amount against each Check Issue Report received by the Bank. On
                each Business Day, the Bank: (a) may pay and charge to the Authorized Account each Presented Check that
                matches by check number and amount a check shown in any Check Issue Report; and (b) shall make available
                through the Online Banking Service by 8:00 AM Pacific Time an Exception Check List that indicates
                whether the Bank has received any Exception Checks and, if so, specifies the complete check number and
                amount of any such Exception Check.
              </p>
              <p>
                <strong>Payment of Exception Checks; Return Requests</strong>. The Bank will pay or return Exception
                Checks pursuant to your Pay/Return Request or pursuant to ONLY ONE of the following default selections:
              </p>
              <p>
                Return Default: If the Return Default Option is selected, the Bank shall return to the depository bank
                any Exception Check drawn on the Authorized Account(s), unless Customer issues a timely Pay/Return
                Request to the Bank.
              </p>
              <p>
                Pay Default. If the Pay Default option is selected, the Bank shall pay and charge to the Authorized
                Account(s), any Exception Check drawn on the Authorized Account(s) unless the Customer issues a timely
                Pay/Return Request to the Bank.
              </p>
              <p>
                A Pay/Return Request shall identify each Exception Check that you desire us to pay or return by the
                complete check number and amount specified in the Exception Check List and shall instruct the Bank to
                pay or return the Exception Check. You must submit the Pay/Return Request to us via the Online Banking
                Service by 11:00 AM Pacific Time of the day the Bank makes the Exception Check List available. The Bank
                shall then charge to the Authorized Account or return to the depositary bank any Exception Check that
                you instruct us to pay or return. We will not be obligated to comply with any Pay/Return Request
                received in a format or medium, after a deadline, or in a manner not permitted under this Agreement but
                may instead treat such a Pay/Return Request as though it had not been received.
              </p>
              <p>
                <strong>Report and Return Request Transmittal</strong>. The Positive Pay Service is entirely web-based,
                meaning that all Check Issue Reports, Exception Check Lists, and Pay/Return Requests will be and must be
                transmitted electronically through the use of the Online Banking Service.
              </p>
              <p>
                In the event of a system failure at the Bank that results in a Check Issue Report, Exception Check List,
                or Pay/Return Request that cannot be received, made available or processed by the Bank, the Bank will
                attempt to contact you. If we cannot reach you, we will act pursuant to your default selection. In the
                event of your system failure, we request that you contact the Commercial Account Service Center at{" "}
                <a href="tel:877-423-9742">877-423-9742</a> or <a href="tel:206-626-8108">206-626-8108</a> to provide us
                with instructions. If you do not contact us, we will act pursuant to your default selection.
              </p>
              <p>
                <strong>Errors</strong>. We are not responsible for detecting any error contained in any Check Issue
                Report or Pay/Return Request you send us. On occasion, a Presented Check may inadvertently appear on an
                Exception Check List when such Presented Check had been provided to the Bank in a previous Check Issue
                Report. You, and not the Bank, are responsible for reconciling such reporting and providing the Bank
                with appropriate instructions per this Agreement.
              </p>
              <strong>Not Covered</strong>. This Agreement does not apply to a check that the Bank has already cashed,
              negotiated or paid or which the Bank is already committed to honor or pay under applicable laws,
              regulations or rules governing such items.
            </li>
            <li>
              <strong>Wrongful/Right Honor</strong>
              <p>
                .<strong>Wrongful Honor</strong>. It shall constitute wrongful honor by the Bank to pay an Exception
                Check that was listed in a timely Exception Check List and which you rejected in a timely Pay/Return
                Request. In the event that there is wrongful honor, the Bank shall be liable to you for the lesser of
                the amount of the wrongfully paid Exception Check or your actual damages resulting from the Bank's
                payment of the Exception Check. The Bank retains all rights and remedies related to any wrongful honor.
              </p>
              <p>
                <strong>Wrongful Dishonor</strong>. Except as provided in this section, it shall constitute wrongful
                dishonor by the Bank to dishonor an Exception Check you approved in a timely Pay/Return Request. We will
                have no liability to you for wrongful dishonor when we, acting in good faith, return an Exception Check:
                (i) we reasonably believed was not properly payable; (ii) because there are insufficient Available Funds
                on deposit in the Authorized Account; or (iii) because we are required to do so by the service of legal
                process on us or the instructions of regulatory or government authorities or courts.
              </p>
              <p>
                <strong>Rightful Payment and Dishonor</strong>. Except as provided in the Faulty Information section of
                this Agreement: (i) If we dishonor an Exception Check because you rejected the Exception Check in a
                Pay/Return Request, the dishonor shall be rightful, and you waive any right it may have to assert that
                the dishonor was wrongful; and (ii) you agree that we exercise ordinary care whenever we rightfully pay
                or return an Exception Check consistent with the provisions of the Agreement.
              </p>
              <p>
                <strong>Faulty Information</strong>. The Bank shall be liable for direct losses caused by its honor of a
                check that was not properly payable, or its dishonor of a check that was properly payable, if the honor
                or dishonor occurred because the Bank, in accordance with this Agreement, (a) should have shown the
                check on an Exception Check Report but failed to do so; or (b) showed the check on an Exception Check
                Report but referenced the wrong check number, unless the Bank provided you with timely information that
                disclosed the error.
              </p>
              <strong>Sufficient Funds</strong>. You will maintain sufficient Available Funds in the Authorized Account
              to cover the aggregate debits made from the Authorized Account. If sufficient funds are not available the
              Bank may, in its sole discretion, either (a) reject any checks or other debit items drawn on the
              Authorized Account or (b) accept any checks or other debit items drawn on any Authorized Account, even if
              such acceptance would bring the Authorized Account negative. The Bank may decide in its sole discretion
              which checks or other debit items to accept or reject and the order in which to accept or reject any such
              debit items. If the Bank must reverse a provisional credit and such reversal causes the Authorized Account
              to have a negative balance, you agree that the Bank may reverse the transfer of any funds and then reject
              any items not finally paid. Fees may apply. Please review the current <em>Fee Schedule</em> available on
              our Website and upon request from your local branch.;
            </li>
          </ol>
          <BackToTopLink id="08" />
        </div>

        <div id="toc-item-9" style={{ scrollMarginTop: offset }}>
          <p className="lead">
            9. Electronic Invoicing through Popmoney
            <sup>&reg;</sup>
          </p>
          <p>
            We offer electronic invoicing through FiServ Solutions Inc. ("FiServ") Popmoney<sup>&reg;</sup>
            service. These services are governed by the Terms and Conditions for Popmoney<sup>&reg;</sup> Transfer
            Service for Small Business ("Popmoney<sup>&reg;</sup> Terms and Conditions"). Fees may apply; please review
            the current Fee Schedule available on our Website and upon request from your local branch. FiServ, the
            service provider, may also charge fees that are specified in the Popmoney<sup>&reg;</sup>
            Terms and Conditions. Between you and us, this Agreement controls.
          </p>
          <BackToTopLink id="09" />
        </div>

        <div id="toc-item-10" style={{ scrollMarginTop: offset }}>
          <p className="lead">10. Fees</p>
          <p>
            You agree to pay all account fees and charges for the Online Banking Services under this Agreement in
            accordance with the <em>Fee Schedule</em> available on our Website and upon request from your local branch.
            We reserve the right to change fees for any service provided through the Online Banking Service. You agree
            that any fees may be deducted automatically from your account (or any other account you have with us if
            there are insufficient funds in that account).
          </p>
          <BackToTopLink id="10" />
        </div>

        <div id="toc-item-11" style={{ scrollMarginTop: offset }}>
          <p className="lead">11. Placing Stop Payment Orders</p>
          <p>
            You can place stop payment orders using our Online Stop Payment service. To place a stop payment on a check
            or a series of checks, sign on to the Online Banking Service and select the "Accounts" menu, then select
            "Stop Payment". All information requested must be provided before we can process a stop payment. Stop
            payment orders using the Online Stop Payment service are subject to the terms of the Business Account
            Agreement. If you send us an online stop payment order, we will send you a stop payment confirmation. If you
            wish to cancel the stop payment or change any information, follow the directions on the confirmation. There
            will be a fee for placing a stop payment, see the <em>Fee Schedule</em> for the current fee.
          </p>
          <BackToTopLink id="11" />
        </div>

        <div id="toc-item-12" style={{ scrollMarginTop: offset }}>
          <p className="lead">12. Electronic Statements and Notices</p>
          <p>
            If you enroll to receive eStatements or disclosures, documentation, and other communications concerning your
            Eligible Accounts ("Notices") electronically, statements or Notices, as applicable, for your Eligible
            Accounts will be available only through Online Banking Services. Your consent to receive eStatements covers
            the account statement for the Eligible Accounts you designate within Online Banking. Your consent to receive
            Notices electronically covers any disclosures, agreements, documentation, and other communications from the
            Bank for the Eligible Accounts you designate within Online Banking. If you enroll in eStatements, the Bank
            may discontinue mailing such account statements and may discontinue mailing Notices to your address of
            record. You agree to be bound by any and all laws, rules, regulations and official issuances applicable to
            eStatements now existing or which may hereafter be enacted, issued or enforced.
          </p>
          <p>
            We will send you notice via e-mail when a new document is available for review. Notice will be sent shortly
            after the end of your statement period and will provide instructions on how to access your eStatement or
            Notice. This is the only notice you receive of the availability of your eStatement or Notice online. We will
            archive electronic statements and Notices for your Eligible Accounts for a rolling 18 months, regardless of
            when you enroll.
          </p>
          <p>
            Your receipt of e-Statements or Notices does not in any way alter your obligation to promptly review your
            transaction history. You must promptly access/review your e-Statement or Notices and any accompanying items
            and notify us immediately of any error, unauthorized transaction, or any other irregularity. The Business
            Account Agreement details all timelines for reporting errors on your account statement.
          </p>
          <p>
            At any time, you may request a paper copy of your account statement. Paper copies may be acquired by
            contacting us by phone at <a href="tel:877-423-9742">877-423-9742</a> or{" "}
            <a href="tel:206-626-8108">206-626-8108</a>, by email to casc@wafd.com, or by US mail at WaFd Bank, Attn:
            Commercial Account Service Center, 425 Pike Street, Seattle, Washington 98101, by requesting through Online
            Banking, or by visiting your local branch. Fees may apply.
          </p>
          <BackToTopLink id="12" />
        </div>

        <div id="toc-item-13" style={{ scrollMarginTop: offset }}>
          <p className="lead">13. Using Email to Send Information and Inquiries to Us</p>
          <p>
            Normal internet e-mail transmissions may not be secure. The Online Banking Service provides secure messaging
            ("Secure Message") for you to ask questions about your Eligible Accounts or give comments on the Online
            Banking Service. Secure Message provides the ability to securely communicate sensitive or confidential
            information. Secure Message is accessible after you sign on with your password to a secure session of the
            Online Banking Service. To ensure the security of your account information, we recommend that you use only
            Secure Message when asking specific questions about your accounts or when sharing sensitive or confidential
            account information about your WaFd Bank accounts. You cannot use email to initiate transactions on your
            accounts. For transactions, please use the appropriate functions within the Online Banking Service or call
            WaFd Bank Commercial Account Service Center at <a href="tel:877-423-9742">877-423-9742</a> or{" "}
            <a href="tel:206-626-8108">206-626-8108</a> or by visiting your local branch.;
          </p>
          <BackToTopLink id="13" />
        </div>

        <div id="toc-item-14" style={{ scrollMarginTop: offset }}>
          <p className="lead">14. Third Party Services</p>
          <p>
            Through our Website we offer access to the following services provided by third parties. These services are
            conducted on the website of these third parties and we are not responsible for the services offered on these
            sites. These other sites may have their own terms and conditions and their own privacy policies and may send
            their own cookies to users, collect data, and solicit personal information. These services include:
          </p>
          <ul>
            <li>scanDeposit</li>
            <li>eLockBox</li>
            <li>
              FedPayments<sup>&reg;</sup>Reporter Service
            </li>
            <li>Federal Taxes</li>
            <li>Harland Clarke</li>
          </ul>
          <BackToTopLink id="14" />
        </div>

        <div id="toc-item-15" style={{ scrollMarginTop: offset }}>
          <p className="lead">15. Privacy</p>
          <p>
            We are committed to protecting your privacy. All information gather from you in connection with using the
            Online Banking Service will be governed by the provisions of the WaFd Bank Privacy Policy as set out in our
            Privacy Notice and our Online Privacy Policy.
          </p>
          <BackToTopLink id="15" />
        </div>

        <div id="toc-item-16" style={{ scrollMarginTop: offset }}>
          <p className="lead">16. Liability Provisions</p>
          <ol>
            <li>
              <strong>Your Liability</strong>
              <br />
              You agree that we are entitled to rely on and deem the use of your User Passwords to gain access to Online
              Banking Services as your authorization for every transaction involving any Eligible Account until you have
              notified the Bank of any possible unauthorized use or transactions. Neither we nor our service providers
              or affiliates will have any liability to you for any unauthorized payment or transfer made using your User
              Passwords that occurs before you have notified us of possible unauthorized use and we have had a
              reasonable opportunity to act on that notice. Authorization of transfer requests and payment requests
              through Online Banking Service by means of your User Passwords shall be considered the same as your signed
              authorization and instruction for the Bank to perform any and all actions relating to the requested
              transaction. You agree to be bound by any transaction on any of your Eligible Accounts using Online
              Banking Service that is initiated by means of your User Passwords and accepted by the Bank in accordance
              with the security procedure outlined in this Agreement or disclosed to you online, regardless of whether
              or not you actually authorized the transaction. You agree not to attempt to use the Online Banking Service
              to make a transfer if you have reason to believe the Online Banking Service is not functioning properly.
            </li>
            <li>
              <strong>System Unavailability and Force Majeure</strong>
              <br />
              The Online Banking Service may be unavailable when systems require maintenance or upgrades or major
              unforeseen events occur, such as earthquakes, fires, floods, computer failures, interruptions in telephone
              service, electrical outages, civil unrest or riots, war or acts or threatened acts of terrorism or other
              circumstances beyond the Bank's control. In the event you or we fail to perform our obligations under this
              Agreement as a consequence of such an event, such failure shall not be considered a breach of this
              Agreement and the disabled party will use its best efforts to meet its obligations under this Agreement.
            </li>
            <li>
              <strong>Limitations on Our Liability</strong>
              <br />
              <p>
                Notwithstanding any other provision in this Agreement, and to the fullest extent permitted by applicable
                law, neither the Bank, its service providers nor any of their affiliates shall be liable, and you
                release and waive any and all claims against all of them, for any and all losses, damages or costs
                incurred by you or by any other person, arising from or relating to any acts or omissions of the Bank,
                its service providers or their affiliates, whether under this Agreement or otherwise, in connection with
                Online Banking Service or any Eligible Account (including, but not limited to, improper calculation or
                processing; transfer of funds or failure to transfer; dishonor or failure to dishonor; payment, stop
                payment, or failure to pay or stop payment; processing of electronic transfer entries; or loss or delay
                of electronic transfer entries, items, instruments, input data and materials during transfer to or from
                the Bank) unless you prove that such losses, damages or costs resulted solely from the Bank's bad faith
                or gross negligence or from breach of this Agreement; provided, however, to the fullest extent permitted
                by applicable law, neither the Bank, its service providers nor any of their affiliates shall be liable
                to you or any other person for any of the following:
              </p>
              <ul>
                <li>
                  For any failure to make a transfer or payment when your Eligible Account is frozen or funds are
                  otherwise unavailable under the terms of your Business Account Agreement;
                </li>
                <li>
                  For any losses, damages or costs, relating to or in any way arising out of the use of the Online
                  Banking Service or the installation, use, or maintenance of your Computer or Software;
                </li>
                <li>
                  For any losses, damages or costs arising from or relating to your failure to maintain the
                  confidentiality and security of your User Passwords, or any other information that enables any other
                  person to gain access to your Eligible Accounts or your subscription to the Online Banking Service;
                  and
                </li>
                <li>
                  For any losses, damages or costs arising from or relating to your failure to receive notices and other
                  communications the Bank has sent to your current address and/or email address shown on the Bank's
                  records, regardless of whether the address on that communication included any designation for delivery
                  to the attention of any particular individual.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Your sole remedy under this Agreement for any errors by the Bank in completing any transfer or bill payment
            you have authorized through the Online Banking Service in accordance with this Agreement, the applicable
            Account Agreements, and applicable law shall be limited as follows:
          </p>
          <blockquote>
            <ul>
              <li>
                If the payment or transfer is not made or is delayed, or if a payment or transfer is made in an
                erroneous amount that is less than the amount authorized by your instructions, unless otherwise required
                by law, the liability of the Bank, its service providers and their affiliates shall be limited to
                correcting the transfer or payment.
              </li>
              <li>
                If the payment or transfer is made in an erroneous amount that exceeds the amount provided in your
                instructions, or the Bank permits an unauthorized payment or transfer after you have provided notice and
                the Bank has had a reasonable time to act on your notice, unless otherwise required by law, the
                liability of the Bank, its service providers and their affiliates shall be limited to a refund of the
                amount erroneously paid or transferred.
              </li>
            </ul>
          </blockquote>
          <p>
            The limitations on the liability of the Bank, its service providers and their affiliates provided in this
            Agreement are in addition to, and shall not diminish, any limitations on this liability contained in any
            applicable Account Agreement or any other agreement between you and the Bank.
          </p>
          <p>
            We agree to make reasonable efforts to ensure the full performance of the Online Banking Service. We will be
            responsible for acting only on those instructions that are actually received and cannot assume
            responsibility for malfunctions in communications facilities not under our control that may affect the
            accuracy or timeliness of messages you send. We are not responsible for any losses or delays in transmission
            of instructions arising out of the use of any Internet service provider, your connection to the
            Internet,your Computer, or caused by any browser software.
          </p>
          <p>
            We are not responsible for any indirect, special, incidental of consequential damages arising in any way out
            of your use of the Online Banking Service. In states that do not allow the exclusion or limitation of
            liability for indirect, special, incidental or consequential damages, our liability is limited to the
            maximum extent permitted by applicable law.
          </p>
          <p>
            WE MAKE NO EXPRESS OR IMPLIED WARRANTIES CONCERNING THE ONLINE BANKING SERVICE, INCLUDING BUT NOT LIMITED
            TO, ANY WARRANTIES OF MERCHANTABILITY OR, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF A THIRD
            PARTY'S PROPRIETARY RIGHTS, OR COMPLIANCE WITH REGULATION E OR ANY OTHER APPLICABLE RULES OR REGULATIONS,
            UNLESS DISCLAIMING SUCH WARRANTIES IS PROHIBITED BY APPLICABLE LAW.
          </p>
          <BackToTopLink id="16" />
        </div>

        <div id="toc-item-17" style={{ scrollMarginTop: offset }}>
          <p className="lead">17. Indemnification</p>
          <p>
            Except to the extent that we are liable under the terms of this Agreement or another agreement governing the
            applicable Eligible Account or Online Financial Service, you agree to indemnify, defend, and hold us, our
            affiliates, officers, directors, employees, consultants, agents, service providers, and licensors harmless
            from any and all third-party claims, liability, damages, and/or costs (including but not limited to
            reasonable attorney's fees) arising from:
          </p>
          <ul>
            <li>
              a third-party claim, action, or allegation of infringement, misuse, or misappropriation based on
              information, data, files, or other materials submitted by you to us;
            </li>
            <li>any fraud, manipulation, or other breach of this Agreement or the Online Banking Service;</li>
            <li>your violation of any law or rights of a third party; or</li>
            <li>your use of the Online Banking Service.</li>
          </ul>
          <p>
            We reserve the right, at our own expense, to assume the exclusive defense and control of any matter
            otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any
            available defenses. You will not settle any action or claims on our behalf without our prior written
            consent. This indemnification is provided without regard to whether our claim for indemnification is due to
            the use of the Online Banking Service by you or your authorized representative or delegate.
          </p>
          <BackToTopLink id="17" />
        </div>

        <div id="toc-item-18" style={{ scrollMarginTop: offset }}>
          <p className="lead">18. Notices and Communication</p>
          <ol>
            <li>
              <strong>Generally</strong>
              <br />
              Except as expressly provided otherwise in this Agreement, we will provide you notices and other
              information regarding your Eligible Accounts, Online Financial Service, or the Online Banking Service
              (collectively "Account-Related Information") through mail, electronically, or by other means available.
              This information will be sent to the postal or electronic address ("E-Address") of the Owner of the
              applicable Eligible Account or Online Financial Service as reflected in our records, unless a different
              postal or electronic address has been specified in accordance with procedures we may establish from time
              to time. The E-Address may be an e-mail address, other Internet address, text messaging number, or other
              electronic access address provided to us in accordance with this Agreement. Account-Related Information
              posted on our Website will be deemed to be delivered to, and received by, you at the time that we display
              it to you, or send notice in accordance with this Agreement that it is posted on our Website, as
              applicable. The person receiving the notice is responsible for providing copies of all Account-Related
              Information to all joint Owners. We reserve the right at all times to communicate all Account-Related
              Information to you through the U.S. Postal Service or overnight courier, at our sole option.
            </li>
            <li>
              <strong>Electronic Delivery and Communication</strong>
              <br />
              <p>
                Unless otherwise required by applicable law, if we are required to provide you with information in
                writing, we may, at our option, send it electronically either:
              </p>
              <ul>
                <li>to your E-Address;</li>
                <li>
                  by posting the information on our Website or to your online banking secure mailbox, and sending you a
                  notice to your postal address or E-Address (either separately or as part of an account statement)
                  telling you that the information has been posted and providing instructions on how to view it; or
                </li>
                <li>by posting the information to our Website.</li>
              </ul>
            </li>
          </ol>
          <p>
            At any time, you may request a paper copy of any communication which has been delivered electronically.
            Paper copies may be acquired by contacting us by phone at <a href="tel:877-423-9742">877-423-9742</a> or{" "}
            <a href="tel:206-626-8108">206-626-8108</a>, by email to casc@wafd.com, or by mail at WaFd Bank, Attn:
            Commercial Account Service Center, 425 Pike Street, Seattle, Washington 98101, or by visiting your local
            branch. Fees may apply.
          </p>
          <p>
            You agree that we may from time to time make telephone calls and send text messages to you in order for us
            to:
          </p>
          <blockquote>
            <ul>
              <li>service your accounts;</li>
              <li>collect any amount you may owe; or</li>
              <li>discuss our relationship, products and services with you.</li>
            </ul>
          </blockquote>
          <p>
            The ways we may call you include using prerecorded/artificial voice messages and/or through the use of an
            automatic telephone dialing system. The ways we may send text messages to you include through the use of an
            automatic telephone dialing system. We may call you and send text messages to your telephone number,
            including mobile/cellular telephone numbers that could result in charges to you.
          </p>
          <ol start={3}>
            <li>
              <strong>Returned, Unclaimed Account-Related Information</strong>
              <br />
              Unless otherwise prohibited by the laws governing your Eligible Account or Online Financial Service, this
              section applies if two or more separate Account-Related Information documents are returned or, in the case
              of electronic notifications, one or more notices are returned as undeliverable. This means we may
              discontinue sending Account-Related Information or electronic notifications to you until you provide a
              valid postal or E-Address to us.;
              <p>Additionally, we may, at our sole option:;</p>
              <ul>
                <li>destroy Account-Related Information that is sent to you and returned to us as undeliverable; or</li>
                <li>
                  discontinue sending the Account-Related Information through the delivery channel generating returns
                  and utilize an alternative delivery channel.
                </li>
                <li>
                  For example, if we are sending your Account-Related Information to you via the U.S. Postal Service,
                  and the information is returned to us as undeliverable on two or more occasions, we may attempt to
                  deliver the information to your E-Address, instead.
                </li>
              </ul>
            </li>
            <li>
              <strong>Address Changes</strong>
              <p>
                You agree that you will notify us immediately in the event of a change to your address or E-Address.
                Address changes may be initiated at your request. You may instruct us to change the address or E-Address
                to which we send notices or Account-Related Information concerning your Account at any time.
              </p>
              <p>
                You may update your e-mail address information by logging in to your Online Banking Service and clicking
                on "Update Profile" in the My Information drop down. Physical address changes must be made through your
                branch or by contacting us by U.S. Mail or the Commercial Account Service Center.
              </p>
              <p>
                Unless you instruct us otherwise, when you notify us of an address change for a specified account, we
                may change the postal or E-Address, as applicable, for all or some of your other account(s) with us.
              </p>
            </li>
          </ol>
          <ol start={5}>
            <li>
              <strong>Implementation of Notices</strong>
              <br />
              Any notice you send us will not be effective until we actually receive it and have a reasonable
              opportunity to act on it. If there is more than one Owner on your Eligible Account, we may send
              Account-Related Information to any one of them. You assume the risk of loss in the mail or otherwise in
              transit. Any notice or Account-Related Information we send you will be effective when mailed, sent
              electronically or otherwise made available to you.
            </li>
          </ol>
          <BackToTopLink id="18" />
        </div>

        <div id="toc-item-19" style={{ scrollMarginTop: offset }}>
          <p className="lead">19. Availability of Services; Business Days</p>
          <p>
            You may access our Website 24 hours a day, every day except during Website maintenance, modification, or
            account update periods, when unexpected problems arise such as problems with internet connections, capacity,
            or the like, and when we, in our sole discretion, make all or part of it unavailable. Also, some services
            are only available on Business Days the Bank is open. Accordingly, even though you can request at any time
            that we perform a service, we cannot necessarily process it immediately, and sometimes we will wait until
            the appropriate Business Day to act upon some of your requests. All requests that, for any reason, are not
            completed on that Business Day will be completed on the next Business Day, unless some other date has been
            designated by a prior arrangement with you, or unless otherwise provided for in the Agreement.
          </p>
          <BackToTopLink id="19" />
        </div>

        <div id="toc-item-20" style={{ scrollMarginTop: offset }}>
          <p className="lead">20. General Provisions</p>
          <ol>
            <li>
              <strong>
                Amendments to this Agreement
                <br />
                Except as otherwise required by law, we may in our sole discretion change the terms of this Agreement
                from time to time and at any time. This may include adding new or different terms to, or removing terms
                from, this Agreement.
              </strong>
              The Bank reserves the right to change the terms and conditions of this Agreement at any time. We shall
              update this Agreement on the Website and we may notify you of such changes by mail or electronic message
              to your most recent addresses listed on our records. Within the time frames required by the effective date
              of the notice, we may also notify you (1) upon login to the Online Banking Service or (2) by providing
              notice on our Website if permitted by applicable law. The notice will be posted or sent at least 30 days
              in advance of the effective date of any additional fees, any stricter limits on the type, amount or
              frequency of transactions or any increase in your responsibility for unauthorized transactions, unless an
              immediate change is necessary to maintain the security of the system. By using the Online Banking Service
              when such changes become effective, you agree to be bound by the revised terms and conditions contained in
              this Agreement. You can decline the changes by discontinuing use of the Online Banking Service and
              notifying us of your election by phone at <a href="tel:877-423-9742">877-423-9742</a> or{" "}
              <a href="tel:206-626-8108">206-626-8108</a>, by email at casc@wafd.com, or by US mail at WaFd Bank, Attn:
              Commercial Account Service Center, 425 Pike Street, Seattle, Washington 98101, or by visiting your local
              branch. Changes to fees or terms applicable to Eligible Accounts are governed by the agreement otherwise
              governing the applicable account. Except as otherwise required by law, any change to this Agreement
              applies only to transactions that occur, or claims that arise, after the amendment becomes effective.
            </li>
            <li>
              <strong>Termination of this Agreement</strong>
              <p>
                Unless otherwise required by applicable law, either you or we may terminate this Agreement and/or your
                access to any Eligible Account or Online Financial Service through the Online Banking Service, in whole
                or in part, at any time without notice.
              </p>
              <p>
                Access to an Eligible Account or Online Financial Service through the Online Banking Service, in whole
                or in part, may be suspended and/or reinstated by us, at our discretion, at any time. If reinstated, the
                then current terms of this Agreement will control. You may request reinstatement of an Eligible Account
                or Online Financial Service by calling WaFd Bank Commercial Account Service Center at{" "}
                <a href="tel:877-423-9742">877-423-9742</a> or <a href="tel:206-626-8108">206-626-8108</a> or by
                visiting your local branch.
              </p>
              <p>
                If you do not access your Eligible Accounts through the Online Banking Service for a period of 180
                consecutive days, we may terminate your Online Banking Service, including Online Financial Services
                accessed through the Website, without prior notice to you.
              </p>
              <p>
                If you wish to cancel any of your Online Banking Services, contact WaFd Bank Customer Service at
                <a href="tel:877-423-9742">877-423-9742</a> or <a href="tel:206-626-8108">206-626-8108</a>, by email at
                casc@wafd.com or by mail at WaFd Bank, Attn: Commercial Account Service Center, 425 Pike Street,
                Seattle, Washington 98101, or by visiting your local branch
              </p>
              All applicable provisions of this Agreement will survive termination by either you or us, including,
              without limitation, provisions related to intellectual property, warranty disclaimers, limitations of
              liability, indemnification, and the miscellaneous provisions.
            </li>
            <li>
              <strong>Governing Law and Attorney Fees</strong>
              <br />
              Each of your Eligible Accounts and Online Banking Services will be governed by the laws described in the
              agreements you have with us regarding those Eligible Accounts or Online Banking Services (for example,
              your Business Account Agreement). This Agreement is governed by federal law and the laws of the state
              where your account is located, without regard to conflict-of-law rules. In any legal action or claim
              arising out of or relating to this Agreement, the prevailing party will be entitled to recover costs and
              reasonable attorney fees. Subject to applicable law, you agree that any action in relation to an alleged
              breach of this Agreement shall be commenced within 1 year of the date of the breach, without regard to the
              date the breach is discovered.
            </li>
            <li>
              <strong> Proprietary Rights</strong>
              <p>
                Other than your personal account information, all content included or available on the Website, such as
                advertisements, text graphics, logos, button icons, images, audio clips, and software, is the property
                of WaFd Bank, and/or third parties and is protected by copyrights, trademarks, or other intellectual and
                proprietary rights. The compilation (meaning the collection, arrangement, and assembly) of all content
                on the Website is the exclusive property of WaFd Bank and/or its licensors and is protected by copyright
                or other intellectual property rights.
              </p>
              The trademarks, logos, and service marks displayed on the Website (collectively the "Trademarks") are the
              registered and unregistered trademarks of WaFd Bank, or third parties. Under no circumstances may you use,
              copy, alter, modify, or change these Trademarks. Nothing contained on the Website should be construed as
              granting by implication or otherwise any license or right to use any Trademark without our express written
              permission, or the express written permission of the third party that has rights to such Trademarks, as
              appropriate.
            </li>
            <li>
              <strong>Entire Agreement</strong>
              <br />
              In combination with other Account Agreements, this Agreement represents the agreement between you and us
              regarding the Online Banking Service and merges and supersedes all previous and contemporaneous written or
              oral agreements and understandings regarding the Online Banking Service. Each of the rules, terms, and
              conditions set forth in this Agreement stand alone. Any term or condition contained in this Agreement
              which is inconsistent with the laws governing the Online Banking Service will be deemed to have been
              modified by us and applied in a manner consistent with such laws. Except as otherwise expressly provided
              in this Agreement, if any provision of this Agreement is held to be invalid or otherwise unenforceable,
              the remainder of the provisions will remain in full force and effect and will in no way be invalidated or
              otherwise affected.
            </li>
            <li>
              <strong>Bank Waivers</strong>
              <br />
              Our failure to act with respect to a breach by you or others does not waive our right to act with respect
              to subsequent or similar breaches.
            </li>
            <li>
              <strong>Jury Trial Waiver</strong>
              <br />
              YOU AND WE EACH AGREE THAT NEITHER YOU NOR WE SHALL (A) SEEK A JURY TRIAL IN ANY LAWSUIT, PROCEEDING,
              COUNTERCLAIM, OR ANY OTHER ACTION BASED UPON, OR ARISING OUT OF, THIS AGREEMENT OR ANY ACCOUNT OR THE
              DEALINGS OF THE RELATIONSHIP BETWEEN YOU OR US, OR (B) SEEK TO CONSOLIDATE ANY SUCH ACTION IN WHICH A JURY
              TRIAL CANNOT BE OR HAS NOT BEEN WAIVED. THE PROVISIONS OF THIS SECTION SHALL BE SUBJECT TO NO EXCEPTIONS.
              NEITHER YOU NOR WE HAVE AGREED WITH OR REPRESENTED TO THE OTHER THAT THE PROVISIONS OF THIS SECTION WILL
              NOT BE FULLY ENFORCED IN ALL INSTANCES. YOU AND WE EACH ACKNOWLEDGE THAT THIS WAIVER HAS BEEN KNOWINGLY
              AND VOLUNTARILY MADE.
            </li>
          </ol>
          <BackToTopLink id="20" />
        </div>
      </section>
    </SimpleLanding>
  );
};

export default CommercialAccountAgreement;
